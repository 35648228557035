import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import isToday from "date-fns/isToday";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import startOfDay from "date-fns/startOfDay";
import { memo, useEffect, useRef } from "react";

import useQueryString from "services/query/useQueryString";
import { actionTypes } from "utils/actionTypes";

import styles from "./Options.module.sass";

const Options = ({ lang = "ru", updateData, translate }) => {
  const isMounted = useRef(false);

  const [from, setFrom] = useQueryString(
    "from",
    startOfDay(new Date()).getTime()
  );
  const [to, setTo] = useQueryString("to", new Date().getTime());
  const [showByTimeInterval, setShowByTimeInterval] = useQueryString(
    "showByTime",
    "hour"
  );
  const [showByActionType, setShowByActionType] = useQueryString(
    "showByActionType",
    "all"
  );
  const [removeTenMinIntervals, toggleRemoveTenMinIntervals] = useQueryString(
    "removeTenMinutes",
    false
  );
  const [removeTwentyMinIntervals, toggleRemoveTwentyMinIntervals] =
    useQueryString("removeTwentyMinutes", false);

  useEffect(() => {
    if (isMounted.current) {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      window.parent.postMessage(
        {
          event: "paramsChanged",
          params,
        },
        "*"
      );
    } else {
      isMounted.current = true;
    }

    updateData((currentData) => ({
      ...currentData,
      from,
      to,
      showByTimeInterval,
      showByActionType,
      removeTenMinIntervals,
      removeTwentyMinIntervals,
    }));
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={lang === "ru" ? ruLocale : enLocale}
    >
      <Grid container spacing={2} padding={2}>
        <Grid item>
          <DatePicker
            renderInput={(props) => <TextField {...props} />}
            label={translate("from")}
            value={from}
            onChange={(from) => setFrom(from.getTime())}
            mask={lang === "ru" ? "__.__.____" : "__/__/____"}
          />
        </Grid>
        <Grid item>
          <DatePicker
            renderInput={(props) => <TextField {...props} />}
            label={translate("to")}
            value={to}
            onChange={(to) => {
              if (isToday(to)) {
                setTo(new Date().getTime());
              } else {
                to.setHours(23, 59, 59, 999);
                setTo(to.getTime());
              }
            }}
            mask={lang === "ru" ? "__.__.____" : "__/__/____"}
          />
        </Grid>
        <Grid item>
          <div className={styles.filterGroup}>
            <label className={styles.filterGroupLabel}>{translate("show_by")}</label>
            <ToggleButtonGroup
              color="primary"
              className={styles.showBy}
              value={showByTimeInterval}
              exclusive
              onChange={(_, newValue) =>
                newValue !== null && setShowByTimeInterval(newValue)
              }
            >
              <ToggleButton value="hour">{translate("hour")}</ToggleButton>
              <ToggleButton value="day">{translate("day")}</ToggleButton>
              <ToggleButton value="week">{translate("week")}</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="action-type">{translate("action_type")}</InputLabel>
            <Select
              labelId="action-type-label"
              id="action-type"
              value={showByActionType}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                setShowByActionType(value);
              }}
              input={<OutlinedInput label={translate("action_type")} />}
              sx={{ width: 250 }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    maxWidth: 250,
                    width: "auto",
                  },
                },
              }}
            >
              {actionTypes.map((actionType) => (
                <MenuItem key={actionType.alias} value={actionType.alias}>
                  {translate(actionType.alias)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <div className={styles.filterGroup}>
            <label className={styles.filterGroupLabel}>{translate("remove")}</label>
            <FormControlLabel
              control={<Checkbox />}
              label={translate("10_minutes")}
              checked={removeTenMinIntervals}
              onChange={(event) =>
                toggleRemoveTenMinIntervals(event.target.checked)
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={translate("20_minutes")}
              checked={removeTwentyMinIntervals}
              onChange={(event) =>
                toggleRemoveTwentyMinIntervals(event.target.checked)
              }
            />
          </div>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default memo(Options);
