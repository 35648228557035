import { useCallback, useEffect, useRef, useState } from "react";

import { requestUsers, requestUsersWork } from "services/api/api";
import useQueryString from "services/query/useQueryString";

import RectanglesChart from "./charts/RectanglesChart";
import Options from "./ui/Options";
import Spinner from "./ui/spinner/Spinner";

function App() {
  const isMounted = useRef(false);

  const [accessToken] = useQueryString("access_token");
  const [lang] = useQueryString("lang", "ru");

  const [loading, setLoading] = useState(true);
  const [data, updateData] = useState({
    users: [],
    work: {},
    from: null,
    to: null,
    showByActionType: null,
    showByTimeInterval: null,
    removeTenMinIntervals: false,
    removeTwentyMinIntervals: false,
  });

  const [i18n, updateI18n] = useState(null);

  const translate = useCallback(
    (id, params, template = "{{param}}") => {
      let translation = i18n && i18n[id] ? i18n[id] : id;

      if (translation && translation.replace && params) {
        for (let paramId in params) {
          translation = translation.replace(
            template.replace("param", paramId),
            params[paramId]
          );
        }
      }

      return translation;
    },
    [i18n]
  );

  useEffect(() => {
    fetch(`/i18n/${["ru", "en"].indexOf(lang) > -1 ? lang : "ru"}.json`)
      .then((response) => response.json())
      .then((i18n) => updateI18n(i18n))
      .catch((error) => console.log("error", error));
  }, [lang]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      requestUsers(accessToken).then((users = []) =>
        updateData((currentData) => ({ ...currentData, users }))
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (data.users.length > 0 && data.from && data.to) {
      setLoading(true);

      requestUsersWork(accessToken, {
        from: new Date(data.from).toISOString(),
        till: new Date(data.to).toISOString(),
        action_type:
          data.showByActionType === "all" ? null : data.showByActionType,
        group_by: data.showByTimeInterval,
      }).then((data = []) => {
        const work = {};

        data.forEach((workData) => {
          const { admin_id } = workData;

          if (!work[admin_id]) {
            work[admin_id] = [];
          }

          work[admin_id].push(workData);
        });

        updateData((currentData) => ({ ...currentData, work }));

        setLoading(false);
      });
    }
  }, [
    data.users.length,
    data.from,
    data.to,
    data.showByActionType,
    data.showByTimeInterval,
    accessToken,
  ]);

  return (
    <div>
      <main>
        {!i18n ? (
          <Spinner />
        ) : (
          <>
            <Options
              lang={lang}
              updateData={updateData}
              translate={translate}
            />
            <RectanglesChart
              loading={loading}
              lang={lang}
              data={data}
              translate={translate}
            />
          </>
        )}
      </main>
    </div>
  );
}

export default App;
