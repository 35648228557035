export const actionTypes = [
  { alias: 'all', name: 'Все действия' },
  { alias: 'user__crop_avatar', name: 'Обрезка аватара' },
  { alias: 'user__approve_avatar', name: 'Одобрение аватара' },
  { alias: 'user__delete_avatar', name: 'Удаление аватара' },
  { alias: 'user__restore_avatar', name: 'Востановление аватара' },
  { alias: 'user__create_avatar_from_image', name: 'Создание аватара из фото' },
  { alias: 'user__edit', name: 'Редактирование пользователя' },
  { alias: 'user__ban', name: 'Бан пользователя' },
  { alias: 'user__unban', name: 'Разбан пользователя' },
  { alias: 'user__delete', name: 'Удаление пользователя' },
  { alias: 'image__crop', name: 'Обрезка фото' },
  { alias: 'image__feature', name: 'Добавление фото в избранные' },
  { alias: 'image__unfeature', name: 'Удаление фото из избранных' },
  { alias: 'image__delete', name: 'Удаление фото' },
  { alias: 'image__restore', name: 'Востановление фото' },
  { alias: 'message__delete', name: 'Удаление сообщения' },
  { alias: 'message__restore', name: 'Востановление сообщения' },
  { alias: 'image__approve', name: 'Одобрение фото' }
];
