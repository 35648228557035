import * as d3 from "d3";
import {
  addDays,
  addHours,
  addWeeks,
  endOfDay,
  format,
  isEqual,
  startOfWeek,
} from "date-fns";
import endOfWeek from "date-fns/endOfWeek";
import ruLocale from "date-fns/locale/ru";
import startOfDay from "date-fns/startOfDay";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

import Spinner from "components/ui/spinner/Spinner";
import { actionTypes } from "utils/actionTypes";
import numberWithCommas from "utils/numberWithCommas";

import Legend from "./Legend";
import styles from "./RectanglesChart.module.sass";

const margin = { top: 50, left: 100, right: 50, bottom: 50 };
const userHeight = 20;

const getTimeDelimiter = (showByTimeInterval) => {
  switch (showByTimeInterval) {
    case "week":
      return d3.timeMonday;
    case "day":
      return d3.timeDay;
    default:
      return d3.timeHour;
  }
};

const getLegendTitle = (showByActionType, translate) => {
  return `${translate(
    actionTypes.find((actionType) => actionType.alias === showByActionType)
      ?.alias
  )} ${translate("per_hour")}`;
};

const RectanglesChart = ({ loading, lang = "ru", data, translate }) => {
  const { width } = useWindowSize();

  const tooltipRef = useRef();
  const legendRef = useRef();
  const svgRef = useRef();

  useEffect(() => {
    if (loading) return;

    const from = data.from ? new Date(data.from) : new Date();
    const to = data.to ? new Date(data.to) : new Date();
    const showByActionType = data.showByActionType || "all";
    const showByTimeInterval = data.showByTimeInterval || "hour";
    const removeTenMinIntervals =
      data.removeTenMinIntervals === undefined
        ? true
        : data.removeTenMinIntervals;
    const removeTwentyMinIntervals =
      data.removeTwentyMinIntervals === undefined
        ? true
        : data.removeTwentyMinIntervals;
    const users = data.users || [];
    const work = data.work || {};

    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);
    const legend = d3.select(legendRef.current);

    const timeScale = d3
      .scaleTime()
      .domain([from, to])
      .rangeRound([margin.left, width - margin.right])
      .clamp(true);

    const sortedUsers = users
      .map((user) => {
        const userWork = work[user.id] || [];
        const { valueSum, hoursSum, excluded } = userWork.reduce(
          (result, workPart) => {
            if (removeTwentyMinIntervals && workPart.total_hours < 0.35) {
              return result;
            }

            if (
              removeTenMinIntervals &&
              workPart.total_hours < 0.183333333333333
            ) {
              return result;
            }

            result.valueSum += workPart.total_actions;
            result.hoursSum += workPart.total_hours;

            if (!result.excluded && workPart.excluded) {
              result.excluded = true;
            }

            return result;
          },
          { valueSum: 0, hoursSum: 0, excluded: false }
        );

        return {
          ...user,
          work: userWork,
          excluded,
          mean:
            hoursSum > 0 ? Math.round((valueSum / hoursSum) * 100) / 100 : 0,
        };
      })
      .filter((user) => user.mean > 0)
      .sort((userA, userB) => {
        if (
          (!userA.excluded && !userB.excluded) ||
          (userA.excluded && userB.excluded)
        ) {
          return userB.mean - userA.mean;
        } else if (userA.excluded) {
          return 1;
        } else {
          return -1;
        }
      });

    const allValues = sortedUsers.map((user) => user.mean);

    const maxValue = d3.max(allValues);

    const steps = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

    const stepMapper = (step) => Math.round(maxValue * step);

    const valueSteps = steps.map(stepMapper);

    const colorSteps = [
      "#DB5E3F",
      "#DB5E3F",
      "#ECA15B",
      "#F7DB84",
      "#E4F492",
      "#A8D598",
      "#6DB496",
      "#6DB496",
      "#6DB496",
      "#6DB496",
    ];

    const color = d3.scaleThreshold(valueSteps, colorSteps);

    // Clear all
    svg.selectAll("*").remove();
    legend.selectAll("*").remove();

    // Init
    svg
      .attr("width", width)
      .attr("height", margin.top + userHeight + sortedUsers.length * userHeight)
      .attr("font-family", "Roboto")
      .attr("font-size", 10);

    const svgBounds = svg.node().getBoundingClientRect();

    if (maxValue > 0) {
      const legendSvg = Legend(color, {
        title: getLegendTitle(showByActionType, translate),
        width: Math.min(420, width - margin.left - margin.right),
        tickFormat: ",",
      });

      d3.select(legendSvg).attr("transform", `translate(${margin.left}, 0)`);

      legend.node().appendChild(legendSvg);
    }

    // X axis
    const xAxis = svg
      .append("g")
      .attr("transform", `translate(0, ${margin.top})`);

    // Draw axis with marks
    xAxis.call(
      d3.axisTop(timeScale).ticks(getTimeDelimiter(showByTimeInterval).every(1))
    );

    // Increase start of a day marks for hour intervals to show day changes
    xAxis.selectAll("line").attr("y2", (d) => {
      if (showByTimeInterval === "hour" && isEqual(startOfDay(d), d)) {
        return -15;
      }
      return -5;
    });

    // Format text on marks and move day marks text to the center of the hours interval
    xAxis
      .selectAll("text")
      .text((d) => {
        const day = format(d, "dd MMM", {
          locale: lang === "ru" ? ruLocale : null,
        });

        const hour = format(d, "kk", {
          locale: lang === "ru" ? ruLocale : null,
        });

        if (showByTimeInterval === "hour") {
          if (isEqual(startOfDay(d), d)) {
            return day;
          }
          return hour;
        }

        return day;
      })
      .attr("transform", (d) => {
        if (showByTimeInterval === "week") {
          const weekWidth =
            Math.min(
              width - margin.right,
              timeScale(endOfWeek(d, { weekStartsOn: 1 }))
            ) - timeScale(startOfWeek(d, { weekStartsOn: 1 }));

          return `translate(${weekWidth / 2}, 0)`;
        } else {
          const dayWidth =
            Math.min(width - margin.right, timeScale(endOfDay(d))) -
            timeScale(startOfDay(d));

          if (showByTimeInterval === "hour") {
            if (isEqual(startOfDay(d), d)) {
              return `translate(${dayWidth / 2}, -20)`;
            }
            return "translate(0, 0)";
          }
          return `translate(${dayWidth / 2}, 0)`;
        }
      });

    // Y axis
    const yAxis = svg
      .append("g")
      .selectAll("g")
      .data(sortedUsers)
      .enter()
      .append("g")
      .attr(
        "transform",
        (_, i) => `translate(0, ${margin.top + userHeight + i * userHeight})`
      );

    yAxis
      .append("text")
      .text((d) => `${d.firstName} ${d.lastName}`)
      .attr("font-family", "sans")
      .attr("x", margin.left - 30)
      .attr("text-anchor", "end");

    yAxis
      .append("circle")
      .attr("cx", margin.left - 10)
      .attr("cy", -3)
      .attr("r", 5)
      .attr("fill", (d) => color(d.mean))
      .style("cursor", "help")
      .on("mouseover", (_, d) => {
        tooltip.style("opacity", 1);
        tooltip.html(
          `<b>${getLegendTitle(
            showByActionType,
            translate
          )}: ${numberWithCommas(Math.round(d.mean))}</b>`
        );
      })
      .on("mousemove", (event) => {
        const tooltipBounds = tooltip.node().getBoundingClientRect();

        tooltip
          .style(
            "left",
            Math.min(
              svgBounds.width - tooltipBounds.width - 10,
              event.pageX - svgBounds.x + 10
            ) + "px"
          )
          .style(
            "top",
            Math.min(
              svgBounds.height - tooltipBounds.height,
              event.pageY - svgBounds.y + 10
            ) + "px"
          );
      })
      .on("mouseleave", () => {
        tooltip.style("opacity", 0);
      });

    // Content
    svg
      .append("g")
      .selectAll("g")
      .data(sortedUsers)
      .enter()
      .append("g")
      .attr(
        "transform",
        (_, i) => `translate(0, ${margin.top + 7 + i * userHeight})`
      )
      .selectAll("rect")
      .data((data) =>
        data.work
          ? data.work.filter(
              (workPart) =>
                workPart.total_actions > 0 &&
                ((removeTwentyMinIntervals && workPart.total_hours >= 0.35) ||
                  !removeTwentyMinIntervals) &&
                ((removeTenMinIntervals &&
                  workPart.total_hours >= 0.183333333333333) ||
                  !removeTenMinIntervals)
            )
          : []
      )
      .enter()
      .append("rect")
      .attr(
        "x",
        (d) => Math.max(margin.left, timeScale(new Date(d.timestamp))) + 1
      )
      .attr("width", (d) => {
        const from = new Date(d.timestamp);

        let to;

        if (showByTimeInterval === "week") {
          to = addWeeks(from, 1);
        }
        if (showByTimeInterval === "day") {
          to = addDays(from, 1);
        }
        if (showByTimeInterval === "hour") {
          to = addHours(from, 1);
        }

        const rectWidth = Math.max(
          0,
          Math.min(timeScale(to), width - margin.right) -
            Math.max(margin.left, timeScale(from)) -
            2
        );

        return rectWidth;
      })
      .attr("height", 18)
      .attr("fill", (d) => {
        const { actions_per_hour, excluded } = d;

        return actions_per_hour > 0 && !excluded
          ? color(actions_per_hour)
          : "#DCDCDC";
      })
      .on("mouseover", (event) => {
        tooltip.style("opacity", 1);

        const targetBounds = event.target.getBBox();
        const { f: targetParentY } =
          event.target.parentNode.transform.baseVal.consolidate().matrix;

        d3.select(event.target)
          .style("stroke", "black")
          .style("cursor", "help");

        svg
          .append("rect")
          .attr("x", targetBounds.x)
          .attr("y", 0)
          .attr("width", targetBounds.width)
          .attr("height", svgBounds.height)
          .attr("fill", "#FF0000")
          .attr("fill-opacity", 0.1)
          .attr("pointer-events", "none")
          .classed("verticalHelper", true);

        svg
          .append("rect")
          .attr("x", 0)
          .attr("y", targetParentY)
          .attr("width", svgBounds.width)
          .attr("height", targetBounds.height)
          .attr("fill", "#FF0000")
          .attr("fill-opacity", 0.1)
          .attr("pointer-events", "none")
          .classed("horizontalHelper", true);
      })
      .on("mousemove", (event, d) => {
        const {
          admin_id,
          actions_per_hour,
          total_actions,
          total_hours,
          dirty_hours,
          timestamp,
        } = d;
        const user = sortedUsers.find((user) => user.id === admin_id);

        const workHours = Math.floor(total_hours);
        const workMinutes = Math.round((total_hours * 60) % 60);

        const meanInfo = `<b>${getLegendTitle(showByActionType, translate)}: ${
          total_hours > 0
            ? numberWithCommas(Math.round(actions_per_hour * 100) / 100)
            : "-"
        }</b>`;
        const actionsInfo = `- ${translate(
          "tooltip_total_actions"
        )}: ${total_actions}`;
        const innefectiveInfo = `- ${translate(
          "tooltip_innefective"
        )}: ${Math.round((dirty_hours / total_hours) * 100)}%`;
        const timeInfo = `- ${translate(
          "tooltip_work_time"
        )}: ${workHours} ${translate(
          "tooltip_hours"
        )} ${workMinutes} ${translate("tooltip_minutes")}`;

        const date = new Date(timestamp);

        const html = `<b>${user.firstName} ${user.lastName}</b> ${format(
          date,
          "dd MMM HH:mm",
          {
            locale: lang === "ru" ? ruLocale : null,
            weekStartsOn: 1,
          }
        )}:
        <br>
        ${meanInfo}
        <br>
        ${actionsInfo}
        <br>
        ${innefectiveInfo}
        <br>
        ${timeInfo}`;

        const tooltipBounds = tooltip.node().getBoundingClientRect();

        tooltip
          .html(html)
          .style(
            "left",
            Math.min(
              svgBounds.width - tooltipBounds.width - 10,
              event.pageX - svgBounds.x + 10
            ) + "px"
          )
          .style(
            "top",
            Math.min(
              svgBounds.height - tooltipBounds.height,
              event.pageY - svgBounds.y + 10
            ) + "px"
          );
      })
      .on("mouseleave", (event) => {
        tooltip.style("opacity", 0);

        svg.select(".verticalHelper").remove();
        svg.select(".horizontalHelper").remove();

        d3.select(event.target).style("stroke", "none").style("cursor", "auto");
      });
  }, [loading, lang, width, data, translate]);

  return (
    <div className={styles.container}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div ref={tooltipRef} className={styles.tooltip}></div>
          <div ref={legendRef}></div>
          <svg ref={svgRef} />
        </>
      )}
    </div>
  );
};

export default RectanglesChart;
