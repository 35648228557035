import omitBy from "lodash/omitBy";

const defaultApiKey = "BEGKbPeShVmYq3t6w9zFJ_McQfTjWnZr4u7x-ADG-KaPdRgUkXp2s5";

const usersMinichatUrl = "https://b.minichat.com/api/v1/admin/";
const usersChatruletkaUrl = "https://b.chatruletka.com/api/v1/admin/";

const requestApi = (url, options) => {
  return fetch(url, options)
    .then((result) => result.json())
    .catch((error) => ({ error }));
};

export const requestUsers = (apiKey) => {
  return requestApi(usersMinichatUrl + "admins?role=admin", {
    method: "GET",
    headers: { "X-Api-Key": apiKey || defaultApiKey },
  }).then(({ users: firstBatchOfUsers = [] }) =>
    requestApi(usersChatruletkaUrl + "admins?role=admin", {
      method: "GET",
      headers: { "X-Api-Key": apiKey || defaultApiKey },
    }).then(({ users: secondBatchOfUsers = [] }) =>
      firstBatchOfUsers.concat(secondBatchOfUsers)
    )
  );
};

export const requestUsersWork = (apiKey, params = {}) => {
  const processedParams = omitBy(params, (value) => value === null);
  const urlSearchParams = new URLSearchParams({
    ...processedParams,
    role: "admin",
  }).toString();

  return requestApi(
    usersMinichatUrl + `admin_action_summaries?${urlSearchParams}`,
    {
      method: "GET",
      headers: { "X-Api-Key": apiKey || defaultApiKey },
    }
  ).then(({ adminActionSummaries: firstBatchOfData = [] }) =>
    requestApi(
      usersChatruletkaUrl + `admin_action_summaries?${urlSearchParams}`,
      {
        method: "GET",
        headers: { "X-Api-Key": apiKey || defaultApiKey },
      }
    ).then(({ adminActionSummaries: secondBatchOfData = [] }) =>
      firstBatchOfData.concat(secondBatchOfData)
    )
  );
};
