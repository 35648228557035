import { useCallback, useState } from "react";

import { getQueryStringValue, setQueryStringValue } from "./query";

function useQueryString(key, initialValue) {
  const [value, setValue] = useState(
    getQueryStringValue(key) === undefined
      ? initialValue
      : getQueryStringValue(key)
  );
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
